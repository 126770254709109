<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Inspirational <span>Creatives</span></h1>
                    <h1>Inspirational <span>Creatives</span></h1>
                </div>
            </div>
        </div>
        <h2>Iconography | Print | Original Artwork</h2>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    <p>The short book titled Inspirational Creatives involved the creation of iconographic elements in a consistent and logical style. Some of these elements needed to represent famous 
                        figures in media as well as their works, and needed to tie into the overall cartoonish appeal of the book itself. These elements were also incorporated into page backgrounds as 
                        repeating patterns.</p>
                </div>
                <div>

                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="the faces from this project" src="../assets/images/portfolio/ic-faces.jpg">
                    <figcaption>Left to right: Stan Lee, Peter Cullen, Mike Mignola, Robert Rodriguez, Neil Gaiman</figcaption>
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="book cover with icons" src="../assets/images/portfolio/ic-cover.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="the page of stan lee" src="../assets/images/portfolio/ic-lee.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="the page of peter cullen" src="../assets/images/portfolio/ic-cullen.jpg">
                </figure>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioInspirational",

}
</script>

<style lang="scss">

</style>